/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
function Tokbox() {
}

Tokbox.fn = {
    init: function() {
        var tokenLaravel = $('meta[name="csrf-token"]').attr('content');
        var apiKey = $('#api-key').val();

        if (apiKey && tokenLaravel) {
            this.tokenLaravel = tokenLaravel;
            this.apiKey = apiKey;
            this.linkSession = $('#url-session').val();
            this.linkToken = $('#url-token').val();
            this.sessionId = $('#session').val();
            this.bookingId = $('#booking-id').val();
            this.mode = $('#mode').val();
            this.session = null;
            this.publisher = null;
            this.subscribers = [];
            this.height = $(document).height() - 30;
            
            if(this.mode != 'user') {
                this.ePublisher = 'publisher';
                this.eSubscribers = 'subscribers';
                this.publisherHeight = 80 * this.height / 100;
                this.subscriberHeight = 30 * this.height / 100;
                this.mainSubscriber = $('#'+ this.eSubscribers);                                       
            } else {
                this.ePublisher = 'subscribers';
                this.eSubscribers = 'publisher';
                this.publisherHeight = 30 * this.height / 100;
                this.subscriberHeight = 80 * this.height / 100;
                this.mainSubscriber = $('#'+ this.ePublisher); 
            }
            
            this.mainSubscriber.attr('style', 'height: ' + this.height + 'px;');
            this.mainSubscriber.niceScroll({
                autohidemode: false,
                railpadding: { top: 0, right: -5, left: 0, bottom: 0 }                
            });   

            Tokbox.fn.join.call(this);
            Tokbox.fn.action.call(this);
            Tokbox.fn.actionBar.call(this);         
            Tokbox.fn.moveScreen.call(this);                     
        }
    },
    moveScreen: function(){  
        // Get titles from the DOM
        // Change main screen
        var publisherContainer = $('#publisher');
        var subscribersContainer = $('#subscribers');

        subscribersContainer.on('click', '.OT_root', function (e) {
            var currentSubscriber = $(e.currentTarget);
            var currentPublisher = publisherContainer.find('.OT_root').first();

            // Switch style
            var styleSubscriber = currentSubscriber.clone().attr('style');
            var stylePublisher = currentPublisher.clone().attr('style');

            /**
             * Move an element into another element instead of clone
             * @link https://stackoverflow.com/a/1279963/10174865
             */
            currentSubscriber.attr('style', stylePublisher);
            currentSubscriber.appendTo(publisherContainer);
            currentPublisher.attr('style', styleSubscriber);
            currentPublisher.appendTo(subscribersContainer);
        });
    },
    action: function() {
        var _this = this;

        $(document).on('click', '.action', function() {
            $('body').loading('toggle');
            
            var id = $(this).attr('id');
            switch (id) {
                case 'session-stop':
                    Tokbox.fn.stop.call(_this);
                    break;
            }
        });
    },
    actionBar: function() {
        $(document).on('click', '.action-bar a', function(){
            $('.action-bar').hide();
            $('body').loading('toggle');
        });
        
        $(document).on('click', '.action-bar button', function(){
            $('.action-bar').hide();
            $('body').loading('toggle');            
        });        
    },
    join: function() {
        var _this = this;

        $('body').loading('toggle');
        $.ajax({
            type: 'POST',
            url: _this.linkToken,
            data: {
                "_token": _this.tokenLaravel,
                "bookingId": _this.bookingId
            }
        }).success(function(response) {
            if (response.success === true) {
                var response = response.data;

                // Initialize an OpenTok Session object.
                _this.session = OT.initSession(_this.apiKey, _this.sessionId);
                _this.session.on('streamCreated', function(event) {
                    if (_this.mode != 'user') {
                        Tokbox.fn.subscriber.call(_this, event);
                    } else {
                        var role = JSON.parse(event.stream.connection.data).role;

                        if (role == 'mentor') {
                            Tokbox.fn.subscriber.call(_this, event);
                        } else {
                            Tokbox.fn.subscriber.call(_this, event, true);
                        }
                    }
                });
                
                _this.session.on("streamDestroyed", function(event) {
//                    if(_this.mode != 'user') {
                        _this.mainSubscriber.getNiceScroll().resize();
//                    }     
                });

                // Connect to the session using your OpenTok API key and the client's token for the session
                _this.session.connect(response.token, function(error) {
                    if (error) {
                        console.error('Failed to connect', error);
                        $('body').loading('toggle');
                    } else {
                        // Initialize a Publisher, and place it into the 'publisher' DOM element.
                        var publisherOptions = {width: '100%', height: _this.publisherHeight, insertMode: 'append', name: response.name};
                        // This assumes that there is a DOM element with the ID 'publisher':                                  
                        _this.publisher = OT.initPublisher(_this.ePublisher, publisherOptions, function(error) {
                            if (error) {
                                console.log('There was an error initilizing the publisher: ', error.name, error.message);
                                $('body').loading('toggle');
                                return;
                            }
                                                            
                            _this.session.publish(_this.publisher, function(error) {
                                if (error) {
                                    console.log('There was an error publishing: ', error.name, error.message);
                                }
                                
                                $('.action-bar').show();
                            });
                            
                            $('body').loading('toggle');
                        });
                    }
                });

                _this.session.on('sessionDisconnected', function(event) {
                    console.log('You were disconnected from the session.', event.reason);
                });
            } else {
                swal("Error!", response.message, "error");
                $('body').loading('toggle');
            }
        });
    },
    stop: function() {
        var _this = this;
        var endSessionLink = $('#url-end-session').val();
        
        $.ajax({
            type: 'POST',
            url: endSessionLink,
            data: {
                "_token": _this.tokenLaravel
            }
        }).success(function(response) {
            if (response.success === true) {
                _this.session.disconnect();
                window.location.href = response.data.link;
            } else {
                swal("Error!", response.message, "error");
            }

            $('body').loading('toggle');
        });
    },
    subscriber: function(event, otherStudent) {
        var _this = this;                        
        
        if(typeof otherStudent != 'undefined') {
            var subs = _this.ePublisher;
            var subHeight = _this.publisherHeight;
        } else {
            var subs = _this.eSubscribers;
            var subHeight = _this.subscriberHeight;
        }
        
        // Called when another client publishes a stream.
        // Subscribe to the stream that caused this event.
        var subscriber = _this.session.subscribe(event.stream, subs, {
            insertMode: 'append',
            width: '100%',
            height: subHeight
        }, function(error) {
            if (error) {
                console.error('Failed to subscribe', error);
            }                                
                        
//            if(_this.mode != 'user') {
                _this.mainSubscriber.getNiceScroll().resize();
//            }            
        });

        _this.subscribers.push(subscriber);
    },
    rule: function() {
        $(document).ready(function() {
            Tokbox.fn.init.call(this);
        });
    }
};

Tokbox.fn.rule();